.text-input,
.form-control {
  outline: 0;
  width: 100%;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid $n-4;
  height: 38px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-color: $p-7;
}

.text-input:focus:not(.text-input-error) {
  border: 1px solid $p-2;
}

.text-input-error {
  border: 1px solid $s-e-2;
}

.text-input-error-label {
  color: $s-e-2;
}

.text-input-mini {
  outline: none;
  border: none;
  font-size: 14px;
  padding: 10px 5px;
  border-radius: 0px;
  max-width: 150px;
  border-bottom: 1px dashed $n-2;
  height: 38px;
}

.text-input-mini:focus {
  border-radius: 4px;
  border: 1px solid $p-2;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat 98%;
}

.radio {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid $n-5;
}

.radio label {
  cursor: pointer;
}

.radio-o {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid $n-5;
}

.radio-o-o {
  width: 10px;
  height: 10px;
  margin: 2px 2px;
  border-radius: 100px;
  background-color: $p-2;
}

.radio-selected {
  border: 1px solid $p-2;
}

.radio-o-selected {
  border: 1px solid $p-2;
}

.radio:hover:not(.radio-selected, .radio-mini) {
  border: 1px solid $n-1;
}

.radio:hover:not(.radio-selected) .radio-o {
  border: 1px solid $n-1;
}

.radio-row {
  display: flex;
}

.radio-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio-mini {
  cursor: pointer;
  min-width: fit-content;
  padding: 6px 2px;
  border-radius: 4px;
  border: none;
}

.form-check-input {
  cursor: pointer;
}

// .form-check-label {
//     cursor: pointer;
// }

.endpoint-search input {
  max-width: 275px;
  width: 100%;
  margin-right: 25px;
}

.form {
  &-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: $n-4;
  }
}

.label {
  color: $n-4;
  padding: 0px;
  margin-bottom: 5px;
  font-size: 14px;
}

.button-radio {
  justify-content: space-around;

  overflow: hidden;

  .radio {
    width: 50%;
    padding: 7px 10px !important;
    border-radius: 0px;
    height: 38px;
    border: 1px solid $n-5;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .radio-row {
      .radio-col .radio-o {
        opacity: 0;
        position: absolute;
      }

      .radio-col {
        p {
          margin-left: 0px !important;
        }
      }
    }
  }

  .radio-selected {
    border: 1px solid $p-1;
    background: $p-7;
    //color: $n-9;
    border-radius: 0px;
  }
}

.form-control,
.form-select,
.dropdown-heading,
.dropdown-container {
  box-shadow: none;
  border-color: $n-4;

  &:focus {
    box-shadow: none !important;
    border-color: $p-2 !important;
  }
}

.input-group-text {
  background-color: $p-7;
  border-color: $n-4;
}

.auto-textarea {
  max-height: calc(100vh - 300px);
}

.textarea-row4 {
  min-height: 100px !important;
  resize: none;
  max-height: 350px;
}

// Hide Input number Field arrow Up & Down

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.header-bidder-modal {
  .disable-field {
    cursor: default;
    pointer-events: none;
    border-color: $n-7;
  }
}

.gam-input {
  word-break: break-all;
}

.country-select {
  .label {
    display: none;
  }
  .country__control {
    border: none;
    min-height: 36px;
    &:hover {
      border: none;
    }
  }
}

.extension {
  .update {
    .text-input,
    .input-group,
    .switch,
    .website,
    .add-button {
      opacity: 1;
      pointer-events: all;
      cursor: default;
    }
  }
  .create {
    opacity: 1;
  }
  .add {
    .text-input,
    .input-group,
    .switch,
    .website,
    .add-button {
      opacity: 0.5;
      pointer-events: none;
      cursor: none;
    }
  }
  &-left,
  &-right {
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
    height: 100%;
  }
}

.disable-extentsion {
  cursor: none !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
.max-qps-error {
  .text-input-error-label {
    margin-left: 66px;
  }
}

.sspdeal-radio {
  .form-group {
    .radio-mini {
      padding: 0px;
    }
  }
}

.ssp-settings-disabled {
  .save-settings {
    .button {
      opacity: inherit;
      cursor: pointer;
      pointer-events: auto;
    }
  }
  .icon {
    .svg-icon {
      opacity: inherit;
      cursor: pointer;
      pointer-events: auto;
    }
  }
  .button {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }
  .text-input {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }
  input[type='checkbox'] {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }
  .react-switch-handle {
    cursor: none;
    pointer-events: none !important;
  }
  .form-select {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }
  .svg-icon {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }
}

/* Works for Chrome, Safari, Edge, Opera */
// input[type='number']::-webkit-outer-spin-button,
// input[type='number']::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.formik-password {
  display: flex;
  position: relative;

  button {
    position: absolute;
    top: 1px;
    right: 2px;
    height: 36px;
    width: 38px;
    border: 1px solid transparent;
    background-color: $n-9;
  }
}

.formik-validate {
  display: flex;
  position: relative;
  flex-wrap: nowrap;

  .valid {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .right-icon {
    border-left: transparent;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
}
