.profile__main {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__image {
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 100px;
    background-position: center center;
    background-size: cover;

    &__icon {
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      border-radius: 100px;

      &__inner {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // height: 100%;
        // width: 100%;
        // padding: 40px;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
  }

  label {
    color: $n-4;
    font-size: 14px;
  }

  &__link {
    cursor: pointer;
    color: $p-2;
  }
  .text-input-mini {
    max-width: 300px;
    width: 100%;
  }
  .profile_lfname {
    .label {
      display: block;
    }
  }
}

.notification__title {
  align-items: center;

  .svg-icon {
    padding-right: 5px;
    width: fit-content;
    width: auto;
    cursor: default;
    filter: invert(49%) sepia(0%) saturate(928%) hue-rotate(200deg) brightness(0%) contrast(15%);
  }

  h2 {
    font-weight: 200;
    width: fit-content;
    padding-left: 5px;
  }
}

.notification__list {
  margin-top: 20px;
  max-width: 500px;

  &__desc {
    color: $n-4;
  }
}

.gloss-table {
  width: 100%;
  margin-top: 24px;

  thead {
    background-color: $n-7;
  }

  tr {
    td,
    th {
      width: 50%;
      word-break: break-all;
    }
  }
}
