.react-select-dropdown {
  .react-select {
    &__control {
      border-color: $n-4 !important;
      box-shadow: none;

      &:hover {
        border-color: $p-2;
        box-shadow: none;
      }
    }

    &__option {
      background: $n-9;
      color: $n-1;

      &:hover {
        background: $p-7;
        color: $p-2;
      }

      &.active {
        background: $p-2;
        color: $n-9;
      }
    }

    &__placeholder {
      background: $n-9;
      //color: $p-2
    }

    &__list {
      height: 300px;
      overflow-y: auto;
    }

    &__footer {
      position: sticky;
      margin: 0px auto;
      left: 0;
      right: 0;
      bottom: -4px;
      height: 50px;
      text-align: center;
      background: $n-9;
      padding: 7px 15px;
    }

    &__menu-list {
      position: relative;
      //overflow: hidden;

      // .button {
      //     position: absolute;
      //     margin: 0px auto;
      //     left: 0;
      //     right: 0;
      // }
    }
  }
}

.dropdown-disable {
  pointer-events: none;
  opacity: 0.5;
}

.react-select-dropdown {
  // &.app-parameters {
  //     .dropdown-heading-value {
  //         span {
  //             position: relative;

  //             &::after {
  //                 content: "App Parameter";
  //                 position: absolute;
  //                 left: 0;
  //                 font-size: 14px;
  //                 color: $n-1;
  //                 background: $n-9;
  //             }
  //         }
  //     }
  // }

  .dropdown-container {
    box-shadow: none !important;
    border-color: $n-4 !important;

    &:focus-within {
      border-color: $p-2 !important;
    }

    .dropdown-heading {
      svg {
        filter: contrast(0%) brightness(100%);

        &:hover {
          cursor: pointer;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(197deg) brightness(90%)
            contrast(103%);
        }
      }
    }
  }

  .dropdown-content {
    .select-item {
      box-shadow: none;
      outline: none;
      border: none;
      &:hover {
        background: $p-6;
        color: $p-2;
      }

      &:focus-visible {
        background-color: $p-6;
      }

      .item-renderer {
        align-items: start;
        input {
          flex-shrink: 0;
        }
      }
      &.selected{
        background: $p-6;
        color: $p-2;
      }
    }
  }
}

.user-invite-dropdown {
  width: 150px;

  &:focus-within {
    border-color: $p-2 !important;
  }
}
