video::-webkit-media-controls-start-playback-button {
  display: none;
}

// button.hidden {
//   display: none;
// }

// video:hover + button {
//   display: block;
// }

// .video-container .play-icon {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   opacity: 0;
//   transition: opacity 0.3s ease-in-out;
// }

// .video-container:hover .play-icon {
//   opacity: 1;
//   height: 470px;
// }
