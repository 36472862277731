.switch-wrap input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-wrap label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 15px;
  background: $n-5;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch-wrap .off + label:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: $n-3;
  border-radius: 90px;
  transition: 0.3s;
}

.switch-wrap .on + label:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: $n-3;
  border-radius: 90px;
  transition: 0.3s;
}

.switch-wrap .on + label {
  background: $n-5;
}

.switch-wrap .on + label:after {
  left: calc(100% - 17px);
  background: $p-2;
}

.switch-wrap label:active:after {
  width: 20px;
}

.disable-field {
  opacity: 0.5;
  pointer-events: none;
}

.enable-switch {
  opacity: inherit;
  pointer-events: auto;
}
