.stripe-form {
  input {
    height: 40px;
  }

  .StripeElement {
    font-size: 14px;
    border: 1px solid $n-4;
    height: 40px;
    display: block;
    // margin: 0px 0 20px 0;
    width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    // font-family: 'Source Code Pro', monospace;
    // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    // border: 0;
    outline: 0;
    border-radius: 4px;
    background: $n-9;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    border-radius: 4px;
    border: 1px solid $p-2;
    // box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    // -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .StripeElement.PaymentRequestButton {
    height: 40px;
  }

  .modal-footer {
    padding: 0px;
  }
}

.stripe-tab {
  height: 60px;
  min-width: 160px;
  padding: 15px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 9px 25px -8px rgba(0, 0, 0, 0.1);
  font-weight: 200;
  letter-spacing: 0.2px;

  &:hover {
    border: 1px solid $p-2;
    color: $p-2;
  }

  &.active {
    border: 1px solid $p-2;
    color: $p-2;
  }
}
