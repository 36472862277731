.pill {
  display: flex;
  justify-content: center;
}

.pill-button {
  color: $n-2;
  padding: 6px 10px;
  background-color: $n-9;
  border: 1px solid $n-5;
  position: relative;

  &:hover {
    background-color: $p-7;
    color: $n-1;
    border-color: $p-2;
  }

  &:not(:last-child) {
    &::after {
      width: 1px;
      height: 100%;
      background: $p-2;
      position: absolute;
      right: 0;
      top: 0;
      content: '';
    }

    border-right: none;
  }

  &:not(:first-child) {
    border-left: none;
  }
}

.pill-button-selected {
  color: $n-1;
  background-color: $p-7;
  border: 1px solid $p-2;
}

.pill-button-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pill-button-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
