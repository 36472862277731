.row {
    display: flex;
    --bs-gutter-y: 0.5rem;
}

.col {
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
}