.tile {
  border: 1px solid transparent;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;

  &-row {
    display: flex;
    column-gap: 12px;
    justify-content: flex-start;
  }
  &-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    color: $n-4;
  }

  b {
    p {
      color: $n-1;
      font-weight: 200;
    }
  }

  &:hover {
    &:not(.tile-selected) {
      background-color: $p-7;
      border: 1px solid $p-2;
    }
    p {
      color: $p-1;
    }

    b {
      p {
        color: $n-1;
      }
    }
  }

  &.disabled {
    &:hover {
      background-color: transparent;
      border: 1px solid transparent;
      p {
        color: $n-4;
      }

      .tile-col {
        p {
          color: $n-1;
        }
      }
    }
  }

  &-selected {
    border: 1px solid $p-2;
    background-color: $p-7;

    p {
      color: $p-1;
    }
  }
}
