.upload-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
  input {
    width: 120px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
