.card {
  width: 100%;
  border: none;
  margin: 0px 0px 16px;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: $n-9;
  border: 1px solid $n-8;

  &:hover {
    // label {
    //     color: $p-1;
    // }
  }

  .card-status {
    // margin-right: 20px;
    margin-right: 10px;
  }

  .card-tag-menu {
    width: fit-content;
    background: $n-9;
    // border-left: 1px solid #1d58d7;
    // border-right: 1px solid #1d58d7;
    // border-top: 1px solid #1d58d7;
    position: relative;

    .card-tag-menu-list {
      z-index: 1;
      background: $n-9;
      min-width: 120px;
      position: absolute;
      //border: 1px solid #1d58d7;
      transform: translateX(-104px);
      padding-top: 3px;
      padding-bottom: 3px;

      p {
        padding: 3px 10px;

        &:hover {
          background-color: $p-2;
          color: $n-9;

          .svg-icon {
            filter: grayscale(100%) sepia(100%);
          }
        }
      }

      .svg-icon {
        margin-right: 10px;
      }
    }
  }
}

.card:hover:not(.card-selected) {
  background-color: $n-8;
}

.card-selected {
  width: 100%;
  border: 1px solid $p-2;
  background-color: $p-7;
}

.card-row {
  display: flex;
  justify-content: space-between;
}

.card-col {
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    color: $n-4;
  }

  b {
    label {
      color: $n-1;
    }
  }
}

.rounded-circle {
  background-color: $p-2;
  width: 28px;
  height: 28px;
  font-size: 12px;
  font-weight: 200;
  object-fit: cover;
}

.card-selected {
  .card-col {
    label {
      color: $n-4;
      //font-weight: lighter;
    }

    b {
      label {
        color: $n-1;
      }
    }
  }
}

.card-expanded {
  .card-row {
    margin-top: 16px;
  }
}

.card-expanded {
  .card-row .card-col {
    min-width: 80px;

    b {
      font-weight: normal;
    }
  }
  &.admin {
    .card-row .card-col {
      min-width: 70px;
    }
  }
}

.card-box {
  padding: 48px;
  background-color: $n-9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
}

.truncate {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-hb {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-payment-method {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.floor-price {
  b {
    label {
      width: 42px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.table-menu {
  .table-menu-list-item {
    &:hover {
      .svg-icon {
        filter: grayscale(100%) sepia(100%);
      }
    }
  }
}

.sidebar-list {
  .table-menu {
    .table-menu-list {
      top: 0px;
      right: 20px;
      left: inherit;
    }
  }
}

.switch-status {
  &.visible {
    opacity: 0.6;
    pointer-events: none;
  }

  .disbale {
    opacity: 0.6;
    pointer-events: none;
  }
}

.card-image {
  img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 100%;
  }
  &.not-available {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    background: $p-2;
    color: $n-9;
    padding-top: 5px;
  }
}
