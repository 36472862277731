.createtag {
  &-title {
    font-weight: lighter;
    font-size: 24px;
    margin-bottom: 20px;
  }

  &-pagination {
    border-radius: 10px;
    height: 5px;
    width: 50px;
    background-color: $n-5;

    &.active {
      background-color: $p-2;
    }
  }

  &-vasturl {
    min-width: 350px;
    padding: 8px;
    height: 310px;
    overflow-y: auto;
    border-radius: 4px;
    position: relative;
    word-break: break-all;
    border: 1px solid $n-4;
    height: calc(100vh - 560px);
    min-height: 180px;

    &-icon {
      background-color: $n-9;
      width: 24px;
      height: 24px;
      position: sticky;
      bottom: 0px;
      right: 0;
      cursor: pointer;
      border-radius: 4px;
      float: right;
    }

    &.auto-height {
      min-height: auto;
    }
  }

  &-macrosurl {
    min-height: calc(100% - 30px);
    overflow-y: auto;
  }
}
