.header-bidder-main {
  &.sticky {
    // position: sticky;
    // top: 0;
  }

  // overflow: hidden;
  // max-height: 345px;

  .light-shadow {
    max-height: 100vh;
    overflow-y: auto;
    margin-top: 35px;
  }
}

.tabs-modal {
  .header-bidder-main {
    &.sticky {
      // position: sticky;
      // top: 0;
    }

    .light-shadow {
      height: 362px;
      overflow-y: auto;
      margin-top: 0px;
    }

    overflow: hidden;
    max-height: 345px;
  }
}

.headerbidder-form {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  &-error{
    span{
      &::first-letter{
        text-transform: capitalize;
      }
    }
  }
  label {
    color: $n-4;
    padding: 0px;
    margin-bottom: 5px;
    font-size: 14px;
    .required{
      margin-left: 3px;
    }
  }
  .form-group{
    .field-description{
      color: $n-4;
      padding: 0px;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
  .form-text {
    display: none;
  }
  .array-item-add {
    width: auto !important;
  }
  .custom-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $n-1;
    background-color: $n-9;
    background-clip: padding-box;
    border: 1px solid $n-4;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;
    margin-bottom: 15px;
  }
  .field-description {
    display: inline-block;
    //margin-left: 10px;
    font-size: 12px;
    &::after {
      content: ')';
    }
    &::before {
      content: '(';
    }
  }

  .form-group {
    margin-bottom: 1rem;
    .control-label {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  .checkbox {
    .field-description {
      display: block;
    }
    label {
      display: flex;
      align-items: center;
      input {
        margin-right: 8px;
      }
    }
  }
  .field-array {
    .field-description {
      margin-bottom: 20px;
    }
    .array-item {
      display: flex;
      width: 100%;
      gap: 15px;
      .col-xs-9 {
        width: calc(100% - 100px);
      }
    }
  }
  .array-item-add,
  .object-property-expand {
    .btn-info {
      background-color: $p-2;
      border-color: $p-2;
      color: $n-9;
      box-shadow: none;
      outline: none;
      i.glyphicon.glyphicon-plus::after {
        content: '+';
        font-style: initial;
      }
    }
  }
  .array-item-remove {
    position: relative;
    box-shadow: none;
    i.glyphicon.glyphicon-remove::after {
      content: '';
      width: 10px;
      background: $n-9;
      height: 1.5px;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  .array-item-toolbox {
    .btn {
      flex: inherit;
      padding-left: initial;
      padding-right: initial;
      font-weight: bold;
      width: 40px;
      box-shadow: none;
    }

    .array-item-move-up {
      background-color: $n-8;
      box-shadow: none;
      i.glyphicon.glyphicon-arrow-up {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 8px;
        height: 8px;
        border-top: 1.5px solid $n-1;
        border-left: 1.5px solid $n-1;
        transform: rotate(40deg);
        margin-top: -5px;

        &::after {
          content: '';
          display: block;
          width: 1.5px;
          height: 13px;
          background-color: black;
          transform: rotate(-42deg) translate(4px, 1px);
          left: 0;
          top: 0;
        }
      }
    }
    .array-item-move-down {
      background-color: $n-8;
      box-shadow: none;
      i.glyphicon.glyphicon-arrow-down {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 8px;
        height: 8px;
        border-top: 1.5px solid $n-1;
        border-left: 1.5px solid $n-1;
        transform: rotate(220deg);

        &::after {
          content: '';
          display: block;
          width: 1.5px;
          height: 13px;
          background-color: black;
          transform: rotate(-42deg) translate(4px, 1px);
          left: 0;
          top: 0;
        }
      }
    }
  }
  #root_keywords {
    display: none;
  }
  &-error {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    ul {
      padding-left: 0px;
      margin-bottom: 0px;
      li {
        list-style: none;
      }
    }
  }
}

.bidder-item:hover {
  background-color: $n-8;
  cursor: pointer;
}

.bidder-item {
  .button-primary {
    visibility: hidden;
  }

  .button-secondary {
    visibility: hidden;
  }

  .button-disabled {
    visibility: hidden;
  }
}

.bidder-item:hover {
  .button-primary {
    visibility: visible;
  }

  .button-secondary {
    visibility: visible;
  }

  .button-disabled {
    visibility: visible;
  }
}

.header-bidder-modal {
  label {
    text-transform: capitalize;
  }

  &.edit {
    .rtb-form {
      &.disable {
        .input-group-text {
          border-color: $n-7;
        }

        input,
        .radio,
        textarea,
        .select-row,
        .gam-macros {
          cursor: default;
          pointer-events: none;
          border-color: $n-7;
        }
        .gam-macros {
          opacity: 0.5;
        }
        .select-row {
          div {
            div {
              border-color: $n-7;
            }
          }
        }

        .radio-o-o {
          opacity: 0.5;
        }

        .switch {
          div {
            opacity: 0.5;
          }

          cursor: default;
          pointer-events: none;
          border-color: $n-7;
        }

        [type='checkbox'],
        .radio-o {
          border-color: $n-6;
        }

        [type='checkbox'] {
          opacity: 0.5;
        }
      }
    }
  }
}
