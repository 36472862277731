.main__container {
  width: 100%;
  overflow: auto;
  padding: 24px 24px;
  height: calc(100vh - 66px);
  margin-left: 352px;

  @media (min-width: 992px) {
    padding: 30px 24px;
  }

  &__box {
    margin-top: 24px;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    //border: 1px solid $n-6;

    .row {
      padding: 0px 0px 20px;
    }

    label {
      color: $n-4;

      &.text-input-error-label {
        color: $s-e-2;
      }
    }

    &__margin {
      .label {
        display: none;
      }
    }
  }
}

.tabs-container {
  width: 100%;
  overflow: auto;
  padding: 24px 24px;
  height: calc(100vh - 66px);
  margin-left: 352px;

  @media (min-width: 992px) {
    padding: 40px 24px;
  }
}
