.signup__wrap {
    display: flex;

    &__left {
        display: flex;
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        height: calc(100vh - 66px);

        &__box {
            margin: 72px;
            row-gap: 16px;
            width: 380px;
            display: flex;
            flex-direction: column;

            &__wrap {
                max-height: 200px;
                overflow-y: auto;
                overflow-x: hidden;
            }

            &__row {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 20px;
                border: 1px solid $n-8;
                padding: 5px;
                border-radius: 4px;
            }

            &__title {
                font-weight: 200;
                margin-top: 0px;
                word-break: break-all;
            }

            &__subtitle {
                line-height: 10px;
                font-size: 10px;
                text-transform: capitalize;
                color: $n-4;
                font-weight: 400;
                display: block;
            }

            &__link {
                margin-top: 15px;
                text-decoration: underline;
                cursor: pointer;
                color: $p-1;
                padding-right: 10px;
            }
        }
    }

    &__right {
        background-size: cover;
        height: calc(100vh - 66px);
        width: calc(100vw - 450px);
        background-position: center center;
        background-repeat: no-repeat;

        &.dsp {
            background-image: url('#{$image-path}/bg.png');
        }

        &.ssp {
            background-image: url('#{$image-path}/bg-ssp.jpg');
        }
    }

    @media (max-width:991px) {
        overflow: hidden;

        &__left {
            background-size: cover;
            height: calc(100vh - 66px);
            width: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('#{$image-path}/bg.png');

            &__box {
                margin: 0px;
                padding: 30px;
                margin: 0 auto;
                max-width: 335px;
            }
        }

        &__right {
            display: none;
        }
    }
}

.joinus {
    display: flex;
    width: 100vw;
    height: calc(100vh - 66px);

    &-wrap {
        margin: auto;
        row-gap: 24px;
        width: 100%;
        max-width: 700px;
        display: flex;
        position: relative;
        flex-direction: column;
    }

    &-title {
        font-weight: 300;
    }

    &-form {
        row-gap: 24px;
        display: flex;
        position: relative;
        flex-direction: column;
    }

    &-footer {
        right: 0px;
        width: 100%;
        bottom: -50px;
        position: absolute;
        justify-content: flex-end;

        p {
            font-weight: 200;
            margin: 6px 12px;
            width: fit-content;
        }
    }
}