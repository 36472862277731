.table-scroll {
    height: 85vh;
    overflow-y: auto;
    margin-bottom: 0;
    display: block;
}

.table-scroll table {
    margin: 0px;
}

.table-scroll thead {
    position: sticky;
    top: 0;
}

.inventory-table .scrollbar.itable {
    // height: 85vh;
    /* width: 150vh; */
    overflow-y: auto;
    margin-bottom: 30px;
    display: block;
}

.inventory-table .itable th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table__responsive {
    $scrollbar-2-thumb-width: 6px;
    $scrollbar-2-thumb-color: $n-4;
    $scrollbar-2-track-color: $n-9;

    // Chrome & Opera
    &::-webkit-scrollbar {
        width: $scrollbar-2-thumb-width;
        height: $scrollbar-2-thumb-width;

        &-track {
            background-color: $scrollbar-2-track-color;
        }

        &-thumb {
            background-color: $scrollbar-2-thumb-color;
        }

        &-track,
        &-thumb {
            border-radius: 50px;
        }
    }

    // Firefox
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color;
}