.view-section {
  width: 100%;
  overflow-y: auto;
  padding: 30px 24px;
  height: calc(100vh - 66px);
  margin-left: 352px;

  .nav {
    justify-content: center;

    &-link {
      color: $n-2;
      padding: 7px 12px;
      background-color: $n-9;
      border: 1px solid transparent; /* Remove the default border */
      font-size: 14px;
      border-radius: 0;

      &.active {
        color: $n-1;
        background-color: $p-7;
        border: 1px solid $p-2 !important; /* Remove the default border */
      }
    }

    &-item {
      &:first-of-type {
        .nav-link {
          border-radius: 4px 0px 0px 4px;
        }
      }
      &:last-of-type {
        .nav-link {
          border-radius: 0px 4px 4px 0px;
        }
      }

      &:not(:first-of-type) {
        .nav-link {
          border-left: 1px solid $n-5; /* Add a left border for non-first items */
        }
      }
      &:not(:last-of-type) {
        .nav-link {
          border-right: 1px solid transparent; /* Add a right border for non-last items */
        }
      }
      .nav-link {
        border: 1px solid $n-5;
      }
    }
  }

  // .nav {
  //   justify-content: center;
  //   &-link {
  //     color: $n-2;
  //     padding: 7px 12px;
  //     background-color: $n-9;
  //     border: 1px solid $n-5;
  //     font-size: 14px;
  //     border-radius: 0;

  //     &.active {
  //       color: $n-1;
  //       background-color: $p-7;
  //       border: 1px solid $p-2;
  //     }
  //   }
  //   &-item {
  //     &:first-of-type {
  //       .nav-link {
  //         border-radius: 4px 0px 0px 4px;
  //       }
  //     }
  //     &:last-of-type {
  //       .nav-link {
  //         border-radius: 0px 4px 4px 0px;
  //       }
  //     }
  //   }
  // }

  .tab-content {
    display: block;
  }
}

.report-table {
  .tab-content {
    display: none;
  }
  .tab-content.tab-content-active {
    display: block;
  }
}
