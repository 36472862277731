.badge {
  height: fit-content;
  font-size: 12px;
  // padding: 6px 8px;
  padding: 4px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  &-success {
    border: 1px solid $s-s-2;
    color: $s-s-2;
  }
  &-primary {
    border: 1px solid $p-3;
    color: $p-3;
  }
  &-secondary {
    border: 1px solid $n-2;
    color: $n-2;
  }
  &-expired {
    border: 1px solid $s-e-2;
    color: $s-e-2;
  }
}
