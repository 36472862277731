.creative-sidebar {
  .newsidebar {
    height: calc(100vh - 67px);
    padding: 20px 0px 0px 25px;

    .sidebar-list {
      height: calc(100vh - 196px); // Default
      // height: 100%;
    }
  }
}

.creative-login {
  height: calc(100vh - 66px);
  display: flex;
  // width: fit-content;
  // flex-direction: column;
  // justify-content: center;

  &-box {
    margin: 80px auto;
    row-gap: 16px;
    width: 380px;
    display: flex;
    flex-direction: column;
  }
}

.creative-subscriptions {
  padding: 40px 40px;
  height: calc(100vh - 66px);
  width: 100%;
  overflow-y: auto;

  .h-discription {
    margin-top: 20px;
    p {
      font-size: 16px;
      letter-spacing: 0.2px;
      font-weight: 400;
      line-height: 25px;
    }
  }

  &-plans {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .card-box {
      padding: 20px 20px;
      width: 300px;
      border-radius: 10px;

      .card-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.creative-videolibrary-social {
  .handler-list {
    max-height: 300px;
    overflow-y: scroll;

    &-item {
      display: flex;
      justify-content: space-between;
      border: 1px solid $n-7;
      padding: 4px 15px;
      border-radius: 5px;
      margin-bottom: 8px;

      &-action {
        display: flex;
        align-items: center;

        .sync-icon {
          font-size: 18px;
          cursor: pointer;
          color: $p-2;
        }

        .synced-icon {
          font-size: 18px;
          color: #43a047;
        }
      }
    }
  }
}

.creative-table-skeleton {
  &__comman {
    background-image: linear-gradient(90deg, $n-7 0px, rgba(229, 229, 229, 0.8) 40px, $n-7 80px);

    background-size: 600px;
    animation: shine-lines 2s infinite ease-out;
  }

  .thumb-hei {
    height: 120px;
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  &__thumbnail-col {
    width: auto; //220px
    height: 120px;
    border-radius: 4px;

    &.initial {
      width: 195px;
    }
  }

  &__col {
    width: auto; //295px
    height: 120px;
    border-radius: 4px;

    &.initial {
      width: 205px;
    }
  }

  &__meta-col {
    width: auto; //295px
    height: 120px;
    border-radius: 4px;

    &.initial {
      width: 110px;
    }
  }
}
