.c-accordion-button {
  background-color: $n-9;
  border: 1px solid $p-6;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-weight: 200;
  font-size: 14px;
  transition: 0.4s;

  &:hover {
    background-color: $p-6;
  }
}

.c-accordian-panel {
  margin: 8px;
  padding: 0px 2px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.c-accordian-panel > p {
  font-size: 14px;
}

.accordion {
  flex-direction: column;
  display: flex;
  max-width: 270px;

  &-title {
    font-weight: 200;
    font-size: 14px;
    transition: 0.4s;
    background-color: transparent;
    word-break: break-word;
    max-width: 270px;
    text-align: left;
    margin-bottom: 10px;

    &:hover {
      background-color: $p-6;
    }
  }

  &-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.accordion-setting {
  border: 1px solid $n-5;
  border-radius: 0px;
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid $n-5;
  }

  .accordion-header {
    font-size: 16px;
    text-align: left;
    padding: 12px;
    border: none;
    //border-bottom: 1px solid $n-5;
    background: transparent;
    text-decoration: none;
    color: $n-1;
    border-radius: 0px;
    position: relative;
    height: auto;

    &:focus {
      box-shadow: none;
    }

    .title {
      font-weight: lighter;
    }
    &.closed-class,
    &.open-class {
      &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 15px;
        display: inline-block;
        width: 13px;
        height: 13px;
        border-right: 2px solid $n-5;
        border-top: 2px solid $n-5;
      }
    }
    &.closed-class {
      &::after {
        transform: rotate(135deg);
        -webkit-transition: transform 0.25s ease;
        -o-transition: transform 0.25s ease;
        transition: transform 0.25s ease;
      }
    }
    &.open-class {
      &::after {
        transform: rotate(310deg);
        -webkit-transition: transform 0.25s ease;
        -o-transition: transform 0.25s ease;
        transition: transform 0.25s ease;
      }
    }
  }

  .accordion-body {
    //border-top: 1px solid $n-5;
  }

  .collapse {
    position: relative;
  }
}

.accordian-more {
  small {
    text-transform: capitalize;
    &.active {
      &::after {
        content: ',';
      }
    }
  }
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spo-discoverby {
  .accordion {
    .accordion-title {
      font-size: 12px;
      padding: 10px 8px;

      // .svg-icon {
      //   float: right;
      // }
    }

    // .collapse.show {
    //   .svg-icon {
    //     rotate: 90deg;
    //   }
    // }

    button {
      border: 1px solid $n-6;

      &:hover {
        background-color: $p-7;
      }
    }

    &-body {
      border-top: none !important;
      border: 1px solid $n-6;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      input[type='search'] {
        width: 100%;
        border: none;
        height: 40px;

        &:focus {
          outline: none;
        }
      }

      .form-check-label {
        margin-top: 4px;
      }
    }
  }
}

.creative-accordion {
  .accordion {
    max-width: 302px;

    &-title {
      max-width: 100%;
    }

    button {
      border: 1px solid $n-6;
      border-radius: 3px;

      &:hover {
        background-color: $p-7;
      }

      &.active {
        border: 1px solid $p-2;
        background-color: $p-7;
      }
    }
  }
}
