.slider {
  width: 100%;
  height: 6px;
  outline: none;
  appearance: none;
  border-radius: 4px;
  background: $n-7;
}

.slider::-webkit-slider-thumb {
  height: 32px;
  width: 32px;
  cursor: pointer;
  appearance: none;
  background-clip: content-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-image: url('#{$icon-path}/slider.svg');
}

.multi-range-slider-1 {
  margin: 10px 0px 0px;

  .bar-left,
  .bar-right {
    background-color: #e0e0de !important;
    padding: 3px 0px !important;
    box-shadow: none !important;
  }

  .bar-inner {
    background-color: $p-2 !important;
    box-shadow: none !important;
    border: none !important;
  }

  .thumb {
    &::before {
      border: none !important;
      box-shadow: none !important;
      background-color: $p-2 !important;
    }
  }

  .caption {
    display: block !important;

    .min-caption,
    .max-caption {
      font-size: 12px !important;
      background-color: transparent !important;
      border-radius: 0 !important;
      padding: 0px !important;
      box-shadow: none !important;
      color: $n-1 !important;
      margin-left: -18px;
    }
  }

  .labels {
    display: none !important;
  }
}

.multi-range-slider .thumb {
  z-index: 0 !important;
}

.slotcount-rangeslider {
  &-dragbutton:last-child {
    display: none !important;
  }
}
