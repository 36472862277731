.spinner {
  width: 100%;
  height: 100vh;

  &-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
  }
}

.suggestion-input {
  .spinner-border {
    border-right-color: transparent !important;
    border-color: $p-2;
  }
}
