.inventory__page {
  .sidebar-collapsed {
    min-width: 300px;
    max-width: 300px;

    @media (min-width: 992px) {
      min-width: 250px;
      max-width: 250px;
    }
    @media (min-width: 1301px) {
      min-width: 352px;
      max-width: 352px;
    }
  }

  .tile {
    &:hover {
      background-color: transparent;
      border: 1px solid transparent;

      p {
        color: $n-4;
      }

      b {
        p {
          color: $n-1;
        }
      }
    }
  }
}

.dsp-inventory {
  padding-left: 0px;
  padding-right: 10px;

  .sidebar-collapsed {
    max-width: 100%;
    height: auto;
    padding: 20px 10px;
    box-shadow: none;
    overflow: visible;

    .filter__sidebar {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;

      &__filed {
        width: 30%;

        &.range__slider {
          display: none !important;
        }
      }
    }
  }

  // &-scrollbody {
  //     overflow-y: auto;
  //     height: 100vh;
  // }
}

.deals-inventory {
  padding-left: 0px;
  padding-right: 10px;

  .sidebar-collapsed {
    max-width: 100%;
    height: auto;
    padding: 20px 0;
    box-shadow: none;

    .filter__sidebar {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 15px;
      justify-content: space-around;

      &__filed {
        width: 25%;

        &.range__slider {
          display: none !important;
        }
      }

      .filter__sidebar__filed__groupe {
        max-width: 120px;
      }
    }
  }
}
