.skeleton {
  &__comman {
    background-image: linear-gradient(90deg, $n-7 0px, rgba(229, 229, 229, 0.8) 40px, $n-7 80px);

    background-size: 600px;
    animation: shine-lines 2s infinite ease-out;
  }

  &__logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  &__line {
    width: 100%;
    height: 20px;
  }

  &__button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
  }

  &__box {
    width: 96px;
    height: 68px;
    border-radius: 4px;
  }

  &__circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

.invite-loader {
  .skeleton {
    &__button {
      width: 90px;
      height: 36px;
    }
  }
}
