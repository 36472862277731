@font-face {
    font-family: 'Avenir';
    src: local('Avenir'),
    url('#{$font-path}/avenir-regular.otf') format('opentype');
}

@font-face {
    font-weight: 200;
    font-family: 'Avenir';
    src: local('Avenir'),
    url('#{$font-path}/avenir-semi-bold.otf') format('opentype');
}

@font-face {
    font-weight: 300;
    font-family: 'Avenir';
    src: local('Avenir'),
    url('#{$font-path}/avenir-bold.otf') format('opentype');
}